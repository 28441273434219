import './App.css';
import awsconfig from './aws-exports';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import Dashboard from './screens/Dashboard';
import SignInScreen from './screens/SignInScreen';

Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(undefined);

  const checkUser = async () => {
    console.log('checking');
    try {
        const authUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        setUser(authUser);
    } catch (e) {
        setUser(null);
    }
  }

  useEffect(() => {
    checkUser();
    const listener = (data) => {
      if (data.payload.event === 'signIn' || data.payload.event === 'signOut') {
          console.log(`${data.payload.event} event just occurred`);
          checkUser();
      }
    };

    const unsubscribe = Hub.listen('auth', listener);
    return unsubscribe;
  }, [])

  if (user === undefined) return <h1>Loading...</h1>
  return (
    <div className="App">
      {user ? 
        <Dashboard user={user} setUser={setUser} /> :
        <SignInScreen />
      }
    </div>
  );
}

export default App;
