import React from 'react';
import landing from '../home-icon.png';
import letter from '../card-icon.png';
import galleryImg from '../gallery-icon-active.png';
import coupons from '../coupon-icon.png';
import { Box, Button, TextField, useMediaQuery } from '@mui/material';
import { Masonry } from '@mui/lab';

const baseUrl = 'https://birthdaygift8e151e3ade6e40db94fca8e182a59817232722-dev.s3.amazonaws.com/public/';

function Gallery({ gallery, setGallery, galleryFiles, setGalleryFiles, images, setImages, deleteImage }) {
    const matches450 = useMediaQuery('(min-width: 450px)');
    const matches750 = useMediaQuery('(min-width: 750px)');
    const matches1000 = useMediaQuery('(min-width: 1000px)');

    const handleChange = (e) => {
        const fileList = e.target.files;
        console.log(fileList.length);
        setGalleryFiles([...galleryFiles, ...fileList]);
        for (let i = 0; i < fileList.length; i++) {
            setImages(prev => [...prev, URL.createObjectURL(fileList[i])]);
        }
    };

    const handleDeleteNew = (index) => {
        console.log(index);
        setGalleryFiles(prev => prev.slice(0, index).concat(prev.slice(index+1)));
        setImages(prev => prev.slice(0, index).concat(prev.slice(index+1)));
    };

    return (
        <>
            <Box component={'form'} style={{ width: matches750 ? '35%' : '100%', backgroundColor: 'aliceblue', display: 'flex', flexDirection: 'column', alignItems: 'center', overflowY: 'auto' }}>
                <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3 style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Gallery Images:</h3>
                    <input type={'file'} multiple name="icon" style={{ width: '100%', backgroundColor: 'white' }} onChange={handleChange} />
                    {gallery.map((img, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{img.url}</p>
                        <Button variant='text' onClick={() => deleteImage(img.id)}>X</Button>
                    </div>)}
                    {galleryFiles.map((img, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <p>{img.name}</p>
                        <Button variant='text' onClick={() => handleDeleteNew(index)}>X</Button>
                    </div>)}
                </div>
            </Box>
            <div style={{ height: '100%', width: matches750 ? '65%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBlock: matches750 ? 0 : 15 }}>
                <div style={{ backgroundColor: 'aliceblue', width: matches1000 ? '40%' : '50%', height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 5}}>
                        {gallery.length > 0 || images.length > 0 ? <Masonry columns={3} spacing={2}>
                            {gallery.map((item, index) => (
                                <div key={index}>
                                    <img
                                    src={baseUrl+item.id}
                                    alt={`gallery img ${index}`}
                                    loading="lazy"
                                    style={{
                                        borderRadius: 4,
                                        display: 'block',
                                        width: '100%',
                                    }}
                                    />
                                </div>
                            ))}
                            {images.map((item, index) => (
                                <div key={index}>
                                    <img
                                    src={item}
                                    alt={`gallery img ${index}`}
                                    loading="lazy"
                                    style={{
                                        borderRadius: 4,
                                        display: 'block',
                                        width: '100%',
                                    }}
                                    />
                                </div>
                            ))}
                        </Masonry> : null}
                    </div>
                    <div style={{ backgroundColor: 'navy', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letter} alt="birthday card icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Birthday Card</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={galleryImg} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'aliceblue', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={coupons} alt="date ideas icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Coupons</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Gallery;