import { Box, Button, TextField, useMediaQuery } from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import logo from '../my_birthday_gift.png';

function SignInScreen() {
    const matches = useMediaQuery('(min-width: 750px');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let user = await Auth.signIn(email, password);
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                Auth.completeNewPassword(user, password);
            }
        } catch (e) {
            alert(e);
            setLoading(false);
        }
    }
    return (
        <Box component={'form'} onSubmit={handleSubmit} style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white'}}>
            <img src={logo} style={{ marginBottom: 50, width: '80%', maxWidth: 400 }} alt="logo" />
            <TextField variant='standard' placeholder='Email' value={email} onChange={e=>setEmail(e.target.value)} style={{ width: !matches ? '55%' : '25%', padding: 10 }} required/>
            <TextField variant='standard' type={'password'} placeholder='Password' value={password} onChange={e=>setPassword(e.target.value)} style={{ width: !matches ? '55%' : '25%', padding: 10 }} required/>
            <Button type="submit" variant='contained' style={{ padding: 10, width: !matches ? '55%' : '25%', backgroundColor: 'navy', borderRadius: 15, marginTop: 30 }}>{loading ? 'Signing in...' : 'Sign in'}</Button>
        </Box>
    );
}

export default SignInScreen;