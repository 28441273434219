/* 
This is the dashboard that customers will use to upload the info for the app
we will need to capture:
1. User's name
2. A photo for the landing page
3. A love letter (text input)
4. Images for the gallery
5. Birthday Coupons (Free form input with dropdown selections to help)

This dashboard will include:
1. tab for each page in the app
2. appopriate input fields for all the data captures above
3. a "demo" screen that shows the layout of the app with their input

 */

import { Button, useMediaQuery } from '@mui/material';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import BirthdayCoupons from '../components/BirthdayCoupons';
import Gallery from '../components/Gallery';
import LandingPage from '../components/LandingPage';
import BirthdayCard from '../components/BirthdayCard';
import { userByEmail } from '../graphql/queries';
import logo from '../my_birthday_gift.png';
import { createCoupon, createPhoto, createUser, deleteCoupon, deletePhoto, updateUser } from '../graphql/mutations';

function Dashboard({ user, setUser }) {
    // tab navigation variable
    const [render, setRender] = useState('landing');

    // variables for mobile app
    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('');
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [letter, setLetter] = useState('');
    const [gallery, setGallery] = useState([]);
    const [birthdayCoupons, setBirthdayCoupons] = useState([]);
    const [userExists, setUserExists] = useState(false);
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);
    
    // delete variables when updating
    const [imagesTrash, setImagesTrash] = useState([]);
    const [couponsTrash, setCouponsTrash] = useState([]);
    
    // new images & coupons when updating
    const [newCoupons, setNewCoupons] = useState([]);
    const [galleryFiles, setGalleryFiles] = useState([]);
    
    // helper variables
    const [loading, setLoading] = useState(false);
    const matches750 = useMediaQuery('(min-width: 750px');
    const matches450 = useMediaQuery('(min-width: 450px');

    useEffect(() => {
        (async () => {
            // Check for an existing user in the dB
            const userDb = await API.graphql(graphqlOperation(userByEmail, { email: user.attributes.email }));
            console.log(userDb.data.userByEmail.items);
            // if there is one, then populate all the data on the User object
            if (userDb.data.userByEmail.items.length > 0) {
                setUserExists(true);
                setUserId(userDb.data.userByEmail.items[0].id);
                setName(userDb.data.userByEmail.items[0].name);
                setFileName(userDb.data.userByEmail.items[0].userLandingPageImageId);
                setLetter(userDb.data.userByEmail.items[0].birthdayCard);
                setGallery(userDb.data.userByEmail.items[0].gallery.items);
                setBirthdayCoupons(userDb.data.userByEmail.items[0].birthdayCoupons.items);
            }
        })();
    }, []);

    const uploadPhotos = async (id, photoArray) => {
        const photoPromises = photoArray.map(async (photo) => {
          try {
            // Create a new entry for the Photo object in the database
            const galleryImageName = photo.name.split(" ").join("_");
            const response = await API.graphql(graphqlOperation(createPhoto, { input: { url: galleryImageName, userGalleryId: id }}));
      
            const photoId = response.data.createPhoto.id; // Assuming the response contains the ID of the created entry
      
            // Upload the file to S3 using the ID as the filename
            await Storage.put(photoId, photo);
      
            return { id: photoId, name: photo.name, status: 'uploaded' };
          } catch (error) {
            return { name: photo.name, status: 'error', message: error.message };
          }
        });
      
        return Promise.all(photoPromises);
    };

    const handleUserCreation = async () => {
        setLoading(true);
        try {
            // check to ensure all mobile app variables are filled in
            if (name === '') throw Error('Name is required on the Landing Page!')
            if (!file) throw Error('A photo is required for the Landing Page!')
            if (letter === '') throw Error('Your card is required on the Birthday Card Page!')
            if (galleryFiles.length < 1) throw Error('You need to upload at least one image to the Gallery!')
            if (newCoupons.length < 1) throw Error('You have to at least have one good birthday coupon idea for the Birthday Coupons Page!')

            // create the photos in the dB & S3
            const selectedFileName = file.name.split(" ").join("_");
            const landingPhoto = await API.graphql(graphqlOperation(createPhoto, { input: { url: selectedFileName }}))
            const storageEvent = await Storage.put(landingPhoto.data.createPhoto.id, file);

            // add the user to the dB
            const userInput = {
                name,
                email: user.attributes.email,
                birthdayCard: letter,
                userLandingPageImageId: storageEvent.key,
            }  
            const newUser = await API.graphql(graphqlOperation(createUser, { input: userInput }));

            // upload all the images to the gallery
            const uploadResults = await uploadPhotos(newUser.data.createUser.id, galleryFiles);
            console.log(uploadResults);

            newCoupons.forEach(coupon => {
                (async () => {
                    const couponInput = {
                        title: coupon.title,
                        description: coupon.description,
                        userBirthdayCouponsId: newUser.data.createUser.id,
                        redeemed: false
                    }
                    await API.graphql(graphqlOperation(createCoupon, { input: couponInput }));
                })();
            })

            alert('Publish successful! The page will refresh when you clicked OK to show the new changes.');
            window.location.reload();
            setUserExists(true);
            setLoading(false);
        } catch (error) {
            alert(error.message);
            setLoading(false);
        }
    };

    const handleUserUpdate = async () => {
        setLoading(true);
        console.log({newCoupons, galleryFiles});
        try {
            // check to ensure all mobile app variables are filled in
            if (name === '') throw Error('Name is required on the Landing Page!')
            if (letter === '') throw Error('Your birthday card is required on the Birthday Card Page!')
            if (gallery.length+galleryFiles.length < 1) throw Error('You need to upload at least one image to the Gallery!')
            if (birthdayCoupons.length+newCoupons.length < 1) throw Error('You have to at least have one good birthday coupon for the Birthday Coupon Page!')

            // create the photos in the dB & S3
            if (file) {
                console.log(fileName);
                await API.graphql(graphqlOperation(deletePhoto, { input: { id: fileName }}))
                await Storage.remove(fileName);

                const selectedFileName = file.name.split(" ").join("_");
                const landingPhoto = await API.graphql(graphqlOperation(createPhoto, { input: { url: selectedFileName }}))
                try {
                    const imageStorage = await Storage.put(landingPhoto.data.createPhoto.id, file);
                    console.log(imageStorage);
                } catch (error) {
                    alert("There was an error loading " + file.name + ". Exited with "+ error.message);
                }
                
                // update the user with new landing page image
                const userInput = {
                    id: userId,
                    name,
                    birthdayCard: letter,
                    userLandingPageImageId: landingPhoto.data.createPhoto.id
                }
                await API.graphql(graphqlOperation(updateUser, { input: userInput }));
            } else {
                // update the user w/o new landing page image 
                const userInput = {
                    id: userId,
                    name,
                    birthdayCard: letter,
                }
                await API.graphql(graphqlOperation(updateUser, { input: userInput }));
            }
            
            // delete all the images & coupons from the trash
            imagesTrash.forEach(async piece => {
                // remove from dB
                await API.graphql(graphqlOperation(deletePhoto, { input: { id: piece }}));

                // remove from s3
                await Storage.remove(piece);
            });

            couponsTrash.forEach(async piece => {
                // remove from dB
                await API.graphql(graphqlOperation(deleteCoupon, { input: { id: piece }}));
            });

            // create all the new images & coupons in the dB
            const uploadResults = await uploadPhotos(userId, galleryFiles);
            console.log(uploadResults);

            newCoupons.forEach(async coupon => {
                const couponInput = {
                    title: coupon.title,
                    description: coupon.description,
                    userBirthdayCouponsId: userId,
                    redeemed: false
                }
                const couponRes = await API.graphql(graphqlOperation(createCoupon, { input: couponInput }));
                console.log(couponRes);
            })

            alert('Update successful! The page will refresh when you clicked OK to show the new changes.');
            window.location.reload();
            setLoading(false);
        } catch (error) {
            alert(error);
            setLoading(false);
        }
    };

    const handleDeleteImage = (id) => {
        setImagesTrash([...imagesTrash, id]);
        setGallery(prev => prev.filter(item => item.id !== id));
    };

    const handleDeleteCoupon = (id) => {
        setCouponsTrash([...couponsTrash, id]);
        setBirthdayCoupons(prev => prev.filter(item => item.id !== id));
    };

    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: '15%', width: '100%', display: 'flex', flexDirection: 'row', backgroundColor: 'white', boxShadow: '1px 1px 10px lightblue', zIndex: 2, justifyContent: 'space-between' }}>
                {!matches450 ? null : <img src={logo} alt="logo" style={{ width: '20%', maxWidth: 100, alignSelf: 'center', paddingLeft: 20 }}/>}
                <div style={{ width: !matches450 ? '100%' : !matches750 ? '80%' : '50%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}>
                    {render !== 'landing' ? <Button style={{ fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('landing')}>Landing Page</Button> : <Button style={{ borderBottom: '2px solid navy', borderRadius: 0, fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('landing')}>Landing Page</Button>}
                    {render !== 'letter' ? <Button style={{ fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('letter')}>Birthday Card</Button> : <Button style={{ borderBottom: '2px solid navy', borderRadius: 0, fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('letter')}>Birthday Card</Button>}
                    {render !== 'gallery' ? <Button style={{ fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('gallery')}>Gallery</Button> : <Button style={{ borderBottom: '2px solid navy', borderRadius: 0, fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('gallery')}>Gallery</Button>}
                    {render !== 'coupons' ? <Button style={{ fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('coupons')}>Birthday Coupons</Button> : <Button style={{ borderBottom: '2px solid navy', borderRadius: 0, fontSize: !matches750 ? 8 : 11 }} onClick={() => setRender('coupons')}>Birthday Coupons</Button>}
                    <Button style={{ fontSize: !matches750 ? 8 : 11 }} onClick={() => Auth.signOut()}>Sign out</Button>
                </div>
            </div>
            <div style={{ height: '85%', width: '100%', display: 'flex', flexDirection: matches750 ? 'row' : 'column' }}>
                {
                render === 'landing' ? <LandingPage name={name} setName={setName} fileName={fileName} setFile={setFile} image={image} setImage={setImage}/> :
                render === 'letter' ? <BirthdayCard letter={letter} setLetter={setLetter} /> :
                render === 'gallery' ? <Gallery gallery={gallery} setGallery={setGallery} galleryFiles={galleryFiles} setGalleryFiles={setGalleryFiles} images={images} setImages={setImages} deleteImage={handleDeleteImage} /> :
                <BirthdayCoupons birthdayCoupons={birthdayCoupons} setBirthdayCoupons={setBirthdayCoupons} newCoupons={newCoupons} setNewCoupons={setNewCoupons} deleteCoupon={handleDeleteCoupon} />
                }
                <Button variant='contained' onClick={!userExists ? handleUserCreation : handleUserUpdate} style={{ height: 50, aspectRatio: 3/1, alignSelf: matches750 ? 'flex-end' : 'center', fontSize: matches750 ? 13 : 11, margin: 10 }} disabled={loading ? true : false}>{!loading ? 'Publish App' : 'Publishing...'}</Button>
            </div>
        </div>
    );
}

const tab1 = {
    width: '25%',
}

export default Dashboard;