import { Box, TextField, useMediaQuery } from '@mui/material';
import React from 'react';
import landing from '../home-icon.png';
import letterImg from '../card-icon-active.png';
import gallery from '../gallery-icon.png';
import coupons from '../coupon-icon.png';

function BirthdayCard({ letter, setLetter }) {
    const matches450 = useMediaQuery('(min-width: 450px)');
    const matches750 = useMediaQuery('(min-width: 750px)');
    const matches1000 = useMediaQuery('(min-width: 1000px)');

    const handleLetterChange = (e) => {
        setLetter(e.target.value);
    };

    return (
        <>
            <Box component={'form'} style={{ width: matches750 ? '35%' : '100%', backgroundColor: 'aliceblue', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3 style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Birthday Card:</h3>
                    <TextField value={letter} onChange={handleLetterChange} style={{ width: '100%', backgroundColor: 'white', overflowY: 'auto' }} multiline />
                </div>
            </Box>
            <div style={{ height: '100%', width: matches750 ? '65%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBlock: matches750 ? 0 : 15 }}>
                <div style={{ backgroundColor: 'aliceblue', width: matches1000 ? '40%' : '50%', height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ width: '80%', height: '80%', padding: 10, backgroundColor: 'white', marginTop: '5%', overflowY: 'auto' }}>
                        {letter ? <p style={{ textAlign: 'start', fontSize: 10, whiteSpace: 'pre-line', fontFamily: 'Montserrat' }}>{letter}</p> : <p style={{ textAlign: 'start', fontSize: 10, fontFamily: 'Montserrat' }}>Birthday card starts here...</p>}
                    </div>
                    <div style={{ backgroundColor: 'navy', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letterImg} alt="birthday card icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'aliceblue', margin: 0}}>Birthday Card</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={gallery} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={coupons} alt="birthday coupon icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Coupons</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BirthdayCard;