/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      name
      email
      birthdayCard
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        items {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        nextToken
      }
      birthdayCoupons {
        items {
          title
          description
          redeemed
          id
          createdAt
          updatedAt
          userBirthdayCouponsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      name
      email
      birthdayCard
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        items {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        nextToken
      }
      birthdayCoupons {
        items {
          title
          description
          redeemed
          id
          createdAt
          updatedAt
          userBirthdayCouponsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      name
      email
      birthdayCard
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        items {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        nextToken
      }
      birthdayCoupons {
        items {
          title
          description
          redeemed
          id
          createdAt
          updatedAt
          userBirthdayCouponsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      title
      description
      redeemed
      id
      createdAt
      updatedAt
      userBirthdayCouponsId
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      title
      description
      redeemed
      id
      createdAt
      updatedAt
      userBirthdayCouponsId
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      title
      description
      redeemed
      id
      createdAt
      updatedAt
      userBirthdayCouponsId
    }
  }
`;
