import { Box, TextField, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import landing from '../home-icon-active.png';
import letter from '../card-icon.png';
import gallery from '../gallery-icon.png';
import coupons from '../coupon-icon.png';

const baseUrl = 'https://birthdaygift8e151e3ade6e40db94fca8e182a59817232722-dev.s3.amazonaws.com/public/';

function LandingPage({ name, setName, fileName, setFile, image, setImage }) {
    const matches450 = useMediaQuery('(min-width: 450px)');
    const matches750 = useMediaQuery('(min-width: 750px)');
    const matches1000 = useMediaQuery('(min-width: 1000px)');

    const handleChange = (event) => {
        setFile(event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <>
            <Box component={'form'} style={{ width: matches750 ? '35%' : '100%', backgroundColor: 'aliceblue', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                    <h3 style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Name:</h3>
                    <TextField value={name} onChange={e=>setName(e.target.value)} style={{ width: '100%', backgroundColor: 'white' }}/>
                </div>
                <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 50}}>
                    <h3 style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Landing page image:</h3>
                    <TextField type={'file'} name="icon" style={{ width: '100%', backgroundColor: 'white' }} onChange={handleChange} />
                </div>
            </Box>
            <div style={{ height: '100%', width: matches750 ? '65%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBlock: matches750 ? 0 : 15 }}>
                <div style={{ backgroundColor: 'aliceblue', width: matches1000 ? '40%' : '50%', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                    {image ? <img src={image} alt="uploaded image" style={{ height: '40%', backgroundSize: 'contain' }} /> : fileName !== '' ? <img src={baseUrl+fileName} alt="uploaded image" style={{ height: '40%', backgroundSize: 'contain' }} /> : <div style={{ width: '100%', height: '40%', backgroundColor: 'lightgrey' }}></div>}
                    <div style={{ width: '100%',  }}>
                        {name !== '' ? <p style={{ padding: 5, marginInline: 5, marginBlock: 0, fontFamily: 'Montserrat', textAlign: 'left', fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Dear {name},</p> : <p style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16, padding: 5, marginInline: 5, marginBlock: 0, fontFamily: 'Montserrat', textAlign: 'left' }}>Dear _________,</p>}
                        <p style={{ textAlign: 'left', padding: 5, marginInline: 5, marginBottom: 25, fontFamily: 'Montserrat', fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>{'\n'}Happy birthday! It's your special day, so to celebrate I created this app! Check out the various tabs to see your birthday gift.</p>
                    </div>
                    <div style={{ backgroundColor: 'navy', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'aliceblue', margin: 0}}>Home</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={letter} alt="birthday card icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Birthday Card</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={gallery} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                        </div>
                        <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={coupons} alt="birthday coupons icon" style={{ width: 15, height: 15 }}/>
                            <p style={{ fontSize: 8, color: 'white', margin: 0}}>Coupons</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LandingPage;