import React, { useState } from 'react';
import landing from '../home-icon.png';
import letter from '../card-icon.png';
import galleryImg from '../gallery-icon.png';
import couponImg from '../coupon-icon-active.png';
import { Box, Button, Checkbox, TextField, useMediaQuery } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import { updateCoupon } from '../graphql/mutations';

function BirthdayCoupons({ birthdayCoupons, setBirthdayCoupons, newCoupons, setNewCoupons, deleteCoupon }) {
    const matches450 = useMediaQuery('(min-width: 450px)');
    const matches750 = useMediaQuery('(min-width: 750px)');
    const matches1000 = useMediaQuery('(min-width: 1000px)');

    const [coupon, setCoupon] = useState('');
    const [couponDescription, setCouponDescription] = useState('');
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [selectedDesc, setSelectedDesc] = useState('');
    const [generated, setGenerated] = useState(null);
    const [visited, setVisited] = useState([]);

    const getRandomItem = () => {
        const coupons = newCoupons.concat(birthdayCoupons);
        console.log(coupons);
        var index;
        if (visited.length === coupons.length) {
            index = Math.floor(Math.random() * coupons.length);
            setVisited([index]);
        } else {
            // get a random index and see if it has already been visited
            do {
                index = Math.floor(Math.random() * coupons.length);
            } while (visited.find(x => x === index) !== undefined) 

            // set the date idea and add the date to the visited array
            setVisited([...visited, index]);
        }
        setGenerated(coupons[index]);
    };

    const handleAddPress = () => {
        if (coupon === '') {
            alert('Cannot submit an empty birthday coupon!')
            return;
        }
        const tempHolder = newCoupons.concat(birthdayCoupons);
        if (tempHolder.find(idea => idea.title === coupon) !== undefined) {
            alert(`${coupon} already added`);
            setCoupon('');
            setCouponDescription('');
            return;
        }
        if (couponDescription === '') {
            alert('Give your birthday coupon a description!')
            return;
        }
        setNewCoupons([...newCoupons, { title: coupon, description: couponDescription }]);
        setCoupon('');
        setCouponDescription('');
    };

    const handleSelect = (e) => {
        setSelectedCoupon(e.target.value);
        switch(e.target.value) {
            case "Interruption-Free Day":
                setSelectedDesc("Enjoy a day with no interruptions, where the world revolves around only you.");
                break;
            case "Homemade Meal":
                setSelectedDesc("Savor a scrumptious meal prepared with love, featuring your favorite dish.");
                break;
            case "Spa Day":
                setSelectedDesc("Indulge in a luxurious day of pampering, with massages, facials, and a rejuvenating bath.");
                break;
            case "Movie Night In":
                setSelectedDesc("Snuggle up and get cozy with tasty snacks and drinks, while watching your all-time favorite movie.");
                break;
            case "Weekend Getaway":
                setSelectedDesc("Embark on a thrilling adventure to a destination of your choice and create unforgettable memories.");
                break;
            case "Car Cleaning":
                setSelectedDesc("Watch your car get a makeover and shine like never before, courtesy of your loved one.");
                break;
            case "Shopping Spree":
                setSelectedDesc("Hit the town with your favorite shopping buddy and spoil yourself with new clothes or accessories.");
                break;
            case "Volunteer Together":
                setSelectedDesc("Give back to the community and make a positive impact while spending quality time together.");
                break;
            case "Tech-Free Day":
                setSelectedDesc("Unplug and recharge your mind, body, and soul with a day of analog activities and human connections.");
                break;
            case "Sightseeing Tour":
                setSelectedDesc("Discover new sights and sounds in your city or a neighboring town, and let your curiosity lead the way.");
                break;
            case "Homemade Dessert":
                setSelectedDesc("Satisfy your sweet tooth with a delectable homemade treat of your choice.");
                break;
            case "Learn New Skill":
                setSelectedDesc("Learn something new and exciting with your loved one, and ignite your passion for lifelong learning.");
                break;
            case "Personalized Gift":
                setSelectedDesc("Cherish a unique and heartfelt gift that captures your memories and special moments together.");
                break;
            case "Game Day":
                setSelectedDesc("Laugh, compete, and have a blast with your favorite board or video games, and let your inner child run wild.");
                break;
            case "Custom Playlist":
                setSelectedDesc("Groove to a tailor-made playlist of your favorite tunes, crafted with love by your music-loving friend.");
                break;
            case "Outdoor Adventure":
                setSelectedDesc("Explore the great outdoors and connect with nature, while experiencing a thrilling outdoor activity.");
                break;
            case "Language Lesson":
                setSelectedDesc("Learn a new language or skill that you've always wanted to master, and unlock new opportunities and perspectives.");
                break;
            case "Pampering Session":
                setSelectedDesc("Treat yourself to a luxurious day of pampering, and emerge feeling refreshed, renewed, and rejuvenated.");
                break;
            case "Relaxation Day":
                setSelectedDesc("Kick back, relax, and let your loved one take care of everything, while you savor a day of rest and relaxation.");
                break;
            default:
                setSelectedDesc("");
                break;
        }
    }

    const handleSelectAddPress = () => {
        if (selectedCoupon === '') {
            alert('Cannot submit an empty birthday coupon idea!')
            return;
        }
        const tempHolder = newCoupons.concat(birthdayCoupons);
        if (tempHolder.find(idea => idea.title === selectedCoupon) !== undefined) {
            alert(`${selectedCoupon} already added`);
            setSelectedCoupon('');
            setSelectedDesc('');
            return;
        }
        setNewCoupons([...newCoupons, { title: selectedCoupon, description: selectedDesc }]);
        setSelectedCoupon('');
        setSelectedDesc('');
    };

    const handleChange = (e) => {
        e.preventDefault();
        setCoupon(e.target.value);
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddPress();
        }
    }

    const handleDeleteNew = (index) => {
        console.log(index);
        setNewCoupons(prev => prev.slice(0, index).concat(prev.slice(index+1)));
    };

    const handleRedeem = async (e, id, index) => {
        const updateRes = await API.graphql(graphqlOperation(updateCoupon, { input: { id: id, redeemed: e.target.checked}}));
        console.log(updateRes.data.updateCoupon);
        var tempHolder = [...birthdayCoupons];
        tempHolder[index] = updateRes.data.updateCoupon;
        setBirthdayCoupons(tempHolder);
    };
    return (
        <>
            <Box component={'form'} style={{ width: matches750 ? '35%' : '100%', backgroundColor: 'aliceblue', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ width: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBlock: 45 }}>
                        <h3 style={{ fontSize: !matches450 ? 11: !matches750 ? 13 : 16 }}>Birthday Coupons:</h3>
                        <TextField placeholder='Beach trip, Foot massage, etc...' value={coupon} name="date" style={{ width: '100%', backgroundColor: 'white' }} onChange={handleChange} onKeyDown={handleEnter}/>
                        <TextField placeholder='Enter a short description for the person to see when they redeem the coupon!' value={couponDescription} name="description" style={{ width: '100%', backgroundColor: 'white', marginTop: 2 }} multiline onChange={(e) => setCouponDescription(e.target.value)} onKeyDown={handleEnter}/>
                        <Button variant='contained' onClick={handleAddPress}>Add Coupon</Button>
                        <select value={selectedCoupon} onChange={handleSelect} style={{ padding: 20, width: '100%', marginTop: 20}}>
                            <option value={''}>Choose from preset birthday coupons</option>
                            <option value={'Interruption-Free Day'}>Interruption-Free Day</option>
                            <option value={'Homemade Meal'}>Homemade Meal</option>
                            <option value={'Spa Day'}>Spa Day</option>
                            <option value={'Movie Night In'}>Movie Night In</option>
                            <option value={'Weekend Getaway'}>Weekend Getaway</option>
                            <option value={'Car Cleaning'}>Car Cleaning</option>
                            <option value={'Shopping Spree'}>Shopping Spree</option>
                            <option value={'Volunteer Together'}>Volunteer Together</option>
                            <option value={'Tech-Free Day'}>Tech-Free Day</option>
                            <option value={'Sightseeing Tour'}>Sightseeing Tour</option>
                            <option value={'Homemade Dessert'}>Homemade Dessert</option>
                            <option value={'Learn New Skill'}>Learn New Skill</option>
                            <option value={'Personalized Gift'}>Personalized Gift</option>
                            <option value={'Game Day'}>Game Day</option>
                            <option value={'Custom Playlist'}>Custom Playlist</option>
                            <option value={'Outdoor Adventure'}>Outdoor Adventure</option>
                            <option value={'Language Lesson'}>Language Lesson</option>
                            <option value={'Relaxation Day'}>Relaxation Day</option>
                        </select>
                        <Button variant='contained' onClick={handleSelectAddPress}>Add Coupon</Button>
                    </div>
                    <div style={{ width: !matches450 ? '95%' : !matches750 ? '82.5%' : '70%', borderTopColor: 'lightgrey', borderTopWidth: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <h3>Coupons:</h3>
                        <div style={{ width: '100%', textAlign: 'start' }}>
                            {birthdayCoupons.map((coupon, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <Checkbox checked={coupon.redeemed} onChange={(e) => handleRedeem(e, coupon.id, index)} />
                                <p>{coupon.title}</p>
                                <Button variant='text' onClick={() => deleteCoupon(coupon.id)}>X</Button>
                            </div>)}
                            {newCoupons.map((coupon, index) => <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <p>{coupon.title}</p>
                                <Button variant='text' onClick={() => handleDeleteNew(index)}>X</Button>
                            </div>)}
                        </div>
                    </div>
                </div>
            </Box>
        <div style={{ height: '100%', width: matches750 ? '65%' : '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBlock: matches750 ? 0 : 15 }}>
            <div style={{ backgroundColor: 'aliceblue', width: matches1000 ? '40%' : '50%', height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '90%', width: '100%', justifyContent: 'center', alignItems: 'center'  }}>
                    {generated ? 
                        <div>
                            <h2 style={{ fontFamily: 'Montserrat'}}>{generated.title}</h2>
                            <p style={{ fontFamily: 'Montserrat'}}>{generated.description}</p>
                        </div> :
                        <div>
                            <p style={{ color: 'black', fontFamily: 'Montserrat', fontSize: matches750 ? 24 : 18, padding: 15, textAlign: 'center' }}>Tap the button to generate a birthday coupon</p>
                            <p style={{ color: 'black', fontFamily: 'Montserrat', fontSize: matches750 ? 16 : 12, padding: 15, textAlign: 'center' }}>When you redeem a coupon, it will display the details!</p>
                        </div>}
                </div>
                <div style={{ width: '70%', height: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                    {generated ? <p style={{ fontSize: 10 }}>Coupons Redeemed: {birthdayCoupons.concat(newCoupons).filter(coupon => coupon.redeemed).length} of {birthdayCoupons.concat(newCoupons).length}</p> : null}
                    <Button onClick={getRandomItem} variant="contained" style={{ backgroundColor: 'navy', fontSize: matches750 ? 8 : 6, padding: 15, borderRadius: 15, width: '100%' }}>Generate Birthday Coupon</Button>
                </div>
                <div style={{ backgroundColor: 'navy', width: '100%', height: '10%', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={landing} alt="landing icon"style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Home</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={letter} alt="birthday card icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Birthday Card</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={galleryImg} alt="gallery icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'white', margin: 0}}>Gallery</p>
                    </div>
                    <div style={{ width: '25%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={couponImg} alt="birthday coupons icon" style={{ width: 15, height: 15 }}/>
                        <p style={{ fontSize: 8, color: 'aliceblue', margin: 0}}>Coupons</p>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default BirthdayCoupons;