/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      name
      email
      birthdayCard
      landingPageImage {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      gallery {
        items {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        nextToken
      }
      birthdayCoupons {
        items {
          title
          description
          redeemed
          id
          createdAt
          updatedAt
          userBirthdayCouponsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
      userLandingPageImageId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        email
        birthdayCard
        landingPageImage {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        gallery {
          nextToken
        }
        birthdayCoupons {
          nextToken
        }
        id
        createdAt
        updatedAt
        userLandingPageImageId
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      url
      id
      createdAt
      updatedAt
      userGalleryId
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        url
        id
        createdAt
        updatedAt
        userGalleryId
      }
      nextToken
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      title
      description
      redeemed
      id
      createdAt
      updatedAt
      userBirthdayCouponsId
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        title
        description
        redeemed
        id
        createdAt
        updatedAt
        userBirthdayCouponsId
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        email
        birthdayCard
        landingPageImage {
          url
          id
          createdAt
          updatedAt
          userGalleryId
        }
        gallery {
          items {
            url
            id
            createdAt
            updatedAt
            userGalleryId
          }
        }
        birthdayCoupons {
          items {
            title
            description
            redeemed
            id
            createdAt
            updatedAt
            userBirthdayCouponsId
          }
        }
        id
        createdAt
        updatedAt
        userLandingPageImageId
      }
      nextToken
    }
  }
`;
